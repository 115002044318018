import { useDialog } from 'src/hooks/use-dialog';

export const SearchButton = () => {
  const dialog = useDialog();

  return (
    <>
    </>
  );
};
