export const users = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/assets/avatars/avatar-anika-visser.png',
    // email: 'demo@devias.io',
    name: 'Anika Visser',
    // password: 'Password123!',
    // confirm_password: '',
    plan: 'Premium'
  }
];
