import { handleUnauthorized } from "src/contexts/auth/jwt-context";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchStatusSearch = async (searchParams) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    return fetch(`${API_ENDPOINT}/api/management/status_search/?${searchParams}`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          handleUnauthorized();
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }
  
  
export const fetchStatusChoice = async (area_id) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/status-choices/?area_id=${area_id}`, requestOptions)
    .then(response => {   
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}


export const fetchStatusHistory = async (id, status_type) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/statushistory/member/${id}/${status_type}/?fields=status,status_sub,status_assign_userid,status_userid`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const fetchStatusSearchChoice = async (area_id) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/checkbox-choices/`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {      
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const fetchStatusTable = async () => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/status-task/`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      const statusMapping = {};
      data.data.forEach(item => {
        statusMapping[item.statuses__status_desc] = item.task_open_close;
      });
      
      return { status: data.status, data: { ...data.data, statusMapping } };
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

