import { handleUnauthorized } from "src/contexts/auth/jwt-context";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchCBO = async (id) => {
  const token = localStorage.getItem('token');
  const myHeaders = { "authorization": `jwt ${token}` };
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetch(`${API_ENDPOINT}/api/management/cbo/?user_id=${id}`, requestOptions);
  if (response.status === 401) {
    handleUnauthorized();
  }
  const data = await response.json();
  return data;
};


export const fetchAllCBO = async () => {
  const token = localStorage.getItem('token');
  const myHeaders = { "authorization": `jwt ${token}` };
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  const response = await fetch(`${API_ENDPOINT}/api/management/cbo/`, requestOptions);
  if (response.status === 401) {
    handleUnauthorized();
  }
  const data = await response.json();
  return data;
};

  export const fetchCBOSearch = async (searchParams) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const response = await fetch(`${API_ENDPOINT}/api/management/cbo_search/?${searchParams}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }
  
    return response.json()
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

export const fetchCustomerById = async (searchParams, rowsPerPage, newPage) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch(`${API_ENDPOINT}/api/management/cbo_search/?${searchParams}&n=${rowsPerPage}&p=${newPage + 1}`, requestOptions)
      .then((response) => {
          if (response.status === 401) {
            handleUnauthorized();
          }
          return response.json();
        })
      .then(data => {
        return data
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }
    
  export const deleteCBO = async (data) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');
    
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };
    return fetch(`${API_ENDPOINT}/api/management/cbo/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }
  
  export const createCBO = async (data) => {
    try {
      const token = localStorage.getItem('token')
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
      };
  
      const response = await fetch(`${API_ENDPOINT}/api/management/cbo/`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e)
    }
  }

  export const updateCBO = async (id, data) => {
    try {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
      };
      const response = await fetch(`${API_ENDPOINT}/api/management/cbo/${id}/`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e);
    }
  }


  export const fetchCBOByID = async (id) => {
    const token = localStorage.getItem('token');
    const myHeaders = { "authorization": `jwt ${token}` };
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'  
    };
    const response = await fetch(`${API_ENDPOINT}/api/management/cbo/?_id=${id}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }
    const data = await response.json();
    return data;
  };
  
  