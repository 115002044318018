import { Avatar, Box, ButtonBase, SvgIcon, Link, Typography } from "@mui/material";
import { useMockedUser } from "src/hooks/use-mocked-user";
import { RouterLink } from "src/components/router-link";
import { paths } from 'src/paths';
import jwt_decode from "jwt-decode";
import {
    getUserById
  } from "src/service";
import { useEffect, useState } from "react";
import { useDispatch } from "src/store";
import { setLoggedInUser } from "src/slices/user";

export const ProfileButton = () => {
    const user = useMockedUser();
    const dispatch = useDispatch()
    const token = localStorage.getItem("token");
    const decodedToken = jwt_decode(token);
    const user_id = decodedToken.user_id;
    const [loggedUser, setLoggedUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userResponse = await getUserById(user_id);
                dispatch(setLoggedInUser(userResponse))
                setLoggedUser(userResponse?.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUser();
    }, [user_id]);

    if (!loggedUser) {
        return null;
    }

  return (
    <>
      <Link
        color="text.primary"
        component={RouterLink}
        href={`${paths.dashboard.users.index}/${user_id}/edit`}
        sx={{
          alignItems: "flex-start", 
          display: "inline-flex",
        }}
        underline="hover"
      > 
        <Typography variant="subtitle2" sx={{ mr: 2, fontWeight: 'bold' }}>{loggedUser.first_name} {loggedUser.last_name}</Typography>
        <SvgIcon sx={{ mr: 2 }}>
        </SvgIcon>
      </Link>
    </>
  );
};
