import { handleUnauthorized } from "src/contexts/auth/jwt-context";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const createCBOUser = async (data) => {
    try {
      const token = localStorage.getItem('token')
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
      };
  
      const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e)
    }
}

export const updateCBOUser = async (id, data) => {
    try {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
      };
  
      const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/${id}/`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e);
    }
}

export const getCBOUserById = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
  
      const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/?_id=${id}`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e);
    }
}

export const getCBOUserByUserId = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/?user_id=${id}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getCBOUserByUserIdComplete = async (id, assignedTo) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    let url = `${API_ENDPOINT}/api/management/cbo_user/?user_id=${id}`;
    if (assignedTo) {
      url += `&assigned_to=${assignedTo}`;
    }

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(url, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const deleteCBOUser = async (id) => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    return fetch(`${API_ENDPOINT}/api/management/cbo_user/${id}/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  
export const fetchUserbyCBOId = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/?cbo_id=${id}`, requestOptions);
  
    if (response.status === 401) {
      handleUnauthorized();
    }
  
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}
