import { handleUnauthorized } from "src/contexts/auth/jwt-context";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";
import { wait } from "src/utils/wait";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchUser = async () => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/`, requestOptions);
  
    if (response.status === 401) {
      handleUnauthorized();
    }
  
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const fetchUserbyId = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    const response = await fetch(`${API_ENDPOINT}/api/management/cbo_user/?id=${id}`, requestOptions);
  
    if (response.status === 401) {
      handleUnauthorized();
    }
  
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const SystemUserSearch = async (searchParams, rowsPerPage, newPage) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/auth/user_search/?${searchParams}`, requestOptions)
    .then(response => { 
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const updateUser = async (id, data) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/users/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }
     
    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const createUser = async (data) => {
  try {
    const token = localStorage.getItem('token')
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/signup/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e)
  }
}


export const getUserById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/users/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getUserCBOById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/users/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}


export const getUsersCBOById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/users/user_id/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getUser = async () => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/auth/users/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

const token = localStorage.getItem("token");

const is_superuser = localStorage.getItem("is_superuser")
export const updateUserPassword = async (avatar, data, id = null) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');
    let url = `${API_ENDPOINT}/api/auth/change_password/`;
    if (id) {
      url += `${id}/`
    }
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(url, requestOptions).then(response => response.text()).then(result => result).catch(error => error);
    await wait(1000);
    const res = await JSON.parse(response)
    if (response.status === 401) {
      handleUnauthorized();
    }
    if (res.status === "ERROR") {
      toast.error(res.error);
      throw new Error(res.error);
    }
    if (res.status === "OK") {
      toast.success("Password updated successfully");
    }

    return await response.json();
  } catch (err) {
    console.error(err);
  }
};

export const deleteUser= async (data) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  
  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(data)
  };
  return fetch(`${API_ENDPOINT}/api/auth/users/`, requestOptions)
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const fetchUserEmails = async (user_id, email) => {
  if (user_id === undefined || email === undefined) {
    return;
  }

  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/auth/user_email_validation/${user_id}/${email}/`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

