import { handleUnauthorized } from "src/contexts/auth/jwt-context";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const createMember = async (data) => {
  try {
    const token = localStorage.getItem('token')
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e)
  }
}

export const updateMember = async (id, data) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getMemberHousingStatus = async (id, timezone) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/housinghistorylist/?timezone=${timezone}&member_id=${id}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getMemberHistoryDetail = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member_history/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getMemberById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member/?_id=${id}`, requestOptions);
    
    if (response.status === 401) {
      handleUnauthorized();
    }
    if (response.status === 404) {
      console.log("Member not found");
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const fetchMemberSearch = async (searchParams, rowsPerPage, newPage) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/member_search/?${searchParams}&n=${rowsPerPage}&p=${newPage + 1}`, requestOptions)
    .then(response => {
      if (response.status === "ERROR") {
        console.log(response)
        console.log("Token expired");
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const deleteMember = async (data) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(data)
  };
  return fetch(`${API_ENDPOINT}/api/management/member/`, requestOptions)
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}


export const getMemberAll = async (id, data) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };
    return fetch(`${API_ENDPOINT}/api/management/member/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  } catch (e) {
    console.log(e);
  }
}

export const fetchMemberIDs = async (member_id, member_name) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/member_id_validation/${member_id}/${member_name}/`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}


export const fetchMemberHistory = async (id) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/member/${id}/history/  `, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}



export const EnrollmentCheck = async (id, data) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/enrollment-status-check/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}
