export const paths = {
  
  // index: '/',
  index: '/dashboard',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/login',
      // register: '/register'
    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      classic: '/auth-demo/forgot-password/classic',
      modern: '/auth-demo/forgot-password/modern'
    },
    login: {
      classic: '/auth-demo/login/classic',
      modern: '/auth-demo/login/modern'
    },
    register: {
      classic: '/auth-demo/register/classic',
      modern: '/auth-demo/register/modern'
    },
    resetPassword: {
      classic: '/auth-demo/reset-password/classic',
      modern: '/auth-demo/reset-password/modern'
    },
    verifyCode: {
      classic: '/auth-demo/verify-code/classic',
      modern: '/auth-demo/verify-code/modern'
    }
  },
  dashboard: {
    index: '/dashboard',
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/members',
      details: '/dashboard/members/',
      edit: '/dashboard/members/:memberId/edit',
      add: '/dashboard/members/add',
      search: '/dashboard/members/search',
      summary: '/dashboard/members/:memberId/summary',
      history: '/dashboard/members/:memberId/history',
      addReferral: '/dashboard/members/:memberId/add-referral',
      addServices: '/dashboard/members/:memberId/add-services',
      flexFunds: '/dashboard/members/:memberId/flex-funds',
      history: '/dashboard/members/:memberId/history',
      historyDetail: '/dashboard/members/:memberId/history/:historyId',
      consentStatus: '/dashboard/members/:memberId/consent-status', 
      enrollmentStatus: '/dashboard/members/:memberId/enrollment-status',
      checkInStatus: '/dashboard/members/:memberId/check-in-status',
      sdohStatus: '/dashboard/members/:memberId/sdoh-status',
      updateReferral: '/dashboard/members/:memberId/update-referral',
      updateServices: '/dashboard/members/:memberId/update-services',
      updateFlexFund: '/dashboard/members/:memberId/update-flexfund',
      updateFlexFundFields: '/dashboard/members/:memberId/flexfund-fields-update',
    },
    statusSearch: { 
      index: '/dashboard/statusSearch',
      details: '/dashboard/statusSearch/',
      edit: '/dashboard/statusSearch/:statusSearchId/edit',
      // add: '/dashboard/statusSearch/add',
      search: '/dashboard/statusSearch/search'
    } ,
    cbo: { 
      index: '/dashboard/cbo',
      details: '/dashboard/cbo/',
      edit: '/dashboard/cbo/:cboId/edit',
      add: '/dashboard/cbo/add',
      search: '/dashboard/cbo/search'
    } ,
    cboUser: {
      index: '/dashboard/cboUser',
      details: '/dashboard/cboUser/',
      edit: '/dashboard/cboUser/:cboUser/edit',
      add: '/dashboard/cboUser/add',
      search: '/dashboard/cboUser/search',
    },
    // users: {
    users: {
      index: '/dashboard/users',
      details: '/dashboard/users/',
      edit: '/dashboard/users/:users/edit',
      add: '/dashboard/users/add',
      search: '/dashboard/users/search',
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    },
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    kanban: '/dashboard/kanban',
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },
  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  401: '/401',
  404: '/404',
  500: '/500'
};
