import React, { useState } from 'react';
import { Button, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import toast from "react-hot-toast";
import { wait } from "src/utils/wait";
import { useNavigate } from "react-router-dom";
import { paths } from "src/paths";
import { updateUserPassword } from 'src/service';
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters/digits"),
  current_password: Yup.string().required("Current password is required"),
  confirm_password: Yup.string().required("Please confirm your new password").oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

const ChangePasswordModal = ({ open, onClose, onSubmit , avatar, id_ }) => {
  
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const params = useParams();
  const navigate = useNavigate();
  const is_superuser = localStorage.getItem("is_superuser")
  // id_to_edit = params.get('')

  const formik = useFormik({
    initialValues: {
      submit: null,
      password: '',
      confirm_password: '',
    },
    validationSchema,
    

onSubmit: async (values, helpers) => {
  let id_ = avatar ? null : params.users
  console.log("in submitttttttttttttttttttttttttttttttt");
    try {
        const user = await updateUserPassword(avatar, {
          ...values,
        }, id_);
        await wait(500);
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        onClose()
        navigate(paths.dashboard.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  },
});

  if (avatar == false){
    validationSchema.fields.current_password = Yup.string();
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff', padding: '20px', borderRadius: '5px' }}>
          <h2>Change Password</h2>
            <>
              {
                (avatar === true) ? (
                <TextField
                label="Current Password"
                type="password"
                name="current_password"
                value={formik.values.current_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.current_password && Boolean(formik.errors.current_password)}
                helperText={formik.touched.current_password && formik.errors.current_password}
                
                fullWidth
                margin="normal"
              />
                ) : (<></>)
              }
              <TextField
                label="New Password"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                // required
                fullWidth
                margin="normal"
              />
              <TextField
                label="Confirm Password"
                type="password"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                // required
                fullWidth
                margin="normal"
              />
            </>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button type="submit" variant="contained" color="primary" style={{ marginRight: '10px' }}>
              Save
            </Button>
            <Button variant="contained" onClick={onClose} >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
