import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import { Issuer } from 'src/utils/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import { useRouter } from 'src/hooks/use-router';

const STORAGE_KEY = 'accessToken';
const ACCESS_TOKEN = 'token';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_UP'] = 'SIGN_UP';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    // const { isAuthenticated, user } = action.payload;
    const { isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      // user
    };
  },
  SIGN_IN: (state, action) => {
    // const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      // user
    };
  },
  SIGN_UP: (state, action) => {
    // const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      // user
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    // user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);
  
export const handleUnauthorized = () => {
  // const navigate = useNavigate();
  // const router = useRouter();

  localStorage.clear();
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'You are not authorized to access this page. Please log in to continue.',
    confirmButtonText: 'Log in',
    cancelButtonText: 'Go back',
    showCancelButton: true,
    confirmButtonColor: '#008000'
  }).then((result) => {
    if (result.isConfirmed) {
      // navigate("/login");
      window.location.href = '/login';
    } else {
      // navigate("/401");
      window.location.href = '/401';
    }
  });
};
  

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(ACCESS_TOKEN);

      if (accessToken) {
        // const user = await authApi.me({ accessToken });

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            // user
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            // user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          // user: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  },
    // eslint-disable-next-line
    []);

  const signIn = useCallback(async (email, password) => {
    // const { token ,is_superuser,id} = await authApi.signIn({ email, password });
    const { token } = await authApi.signIn({ email, password });
    // const { accessToken, token } = await authApi.signIn({ email, password });
    // const user = await authApi.me({ accessToken });
    // localStorage.setItem(IS_SUPERUSER, is_superuser);
    localStorage.setItem(ACCESS_TOKEN, token);
    // localStorage.setItem(ID, id);
    dispatch({
      type: ActionType.SIGN_IN,
      // payload: {
      //   user
      // }
    })
  }, [dispatch]);

  const signUp = useCallback(async (email, name, password) => {
    const { accessToken, token } = await authApi.signUp({ email, name, password});
    // const { token } = await authApi.signUp({ email, name, password });
    // const user = await authApi.me({ accessToken });

    localStorage.setItem(STORAGE_KEY, accessToken);
    localStorage.setItem(ACCESS_TOKEN, token);

    dispatch({
      type: ActionType.SIGN_UP,
      // payload: {
      //   user
      // }
    });
  }, [dispatch]);

  const signOut = useCallback(async () => {
    localStorage.clear();
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
