import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
// import AddReferral from 'src/pages/dashboard/members/add-referral';
// import AddServices from 'src/pages/dashboard/members/add-services';
// import FlexFunds from 'src/pages/dashboard/members/flex-funds';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// Academy
const AcademyDashboardPage = lazy(() => import('src/pages/dashboard/academy/dashboard'));
const AcademyCoursePage = lazy(() => import('src/pages/dashboard/academy/course'));

// Blog
const BlogPostListPage = lazy(() => import('src/pages/dashboard/blog/list'));
const BlogPostDetailPage = lazy(() => import('src/pages/dashboard/blog/detail'));
const BlogPostCreatePage = lazy(() => import('src/pages/dashboard/blog/create'));

// Members
const MemberListPage = lazy(() => import('src/pages/dashboard/members/list'));
const MemberDetailPage = lazy(() => import('src/pages/dashboard/members/detail'));
const MemberEditPage = lazy(() => import('src/pages/dashboard/members/edit'));
const MemberAddPage = lazy(() => import('src/pages/dashboard/members/add'));
const MemberSearchPage = lazy(() => import('src/pages/dashboard/members/search'));
const MemberSummaryPage = lazy(() => import('src/pages/dashboard/members/summary-detail'));
const MemberHistoryPage = lazy(() => import('src/pages/dashboard/members/history'));
const MemberHistoryDetailPage = lazy(() => import('src/pages/dashboard/members/history-detail'));
const MemberConsentPage = lazy(() => import('src/pages/dashboard/members/consent'));
const MemberCheckInPage = lazy(() => import('src/pages/dashboard/members/check-in-status'));
const MemberEnrollmentPage = lazy(() => import('src/pages/dashboard/members/enrollment-status'));
const MemberSdohPage = lazy(() => import('src/pages/dashboard/members/sdoh-status'));
const MemberServicesPage = lazy(() => import('src/pages/dashboard/members/add-services'));
const MemberFlexFundPage = lazy(() => import('src/pages/dashboard/members/flex-funds'));
const MemberReferralPage = lazy(() => import('src/pages/dashboard/members/add-referral'));
const MemberReferralUpdatePage = lazy(() => import('src/pages/dashboard/members/update-referral'));
const MemberServiceUpdatePage = lazy(() => import('src/pages/dashboard/members/update-services'));
const MemberFlexFundUpdatePage = lazy(() => import('src/pages/dashboard/members/update-flexfund'));
const MemberFlexFundFieldsUpdatePage = lazy(() => import('src/pages/dashboard/members/flexfund-fields-update'));

// StatusSearch
const StatusSearchListPage = lazy(() => import('src/pages/dashboard/statusSearch/list'));
// const StatusSearchAddPage = lazy(() => import('src/pages/dashboard/statusSearch/add'));
// const StatusSearchDetailPage = lazy(() => import('src/pages/dashboard/statusSearch/detail'));
// const StatusSearchEditPage = lazy(() => import('src/pages/dashboard/statusSearch/edit'));
const StatusSearchSearchPage = lazy(() => import('src/pages/dashboard/statusSearch/search'));

// CBO
const CBOListPage = lazy(() => import('src/pages/dashboard/cbo/list'));
const CBOAddPage = lazy(() => import('src/pages/dashboard/cbo/add'));
const CBODetailPage = lazy(() => import('src/pages/dashboard/cbo/detail'));
const CBOEditPage = lazy(() => import('src/pages/dashboard/cbo/edit'));
const CBOSearchPage = lazy(() => import('src/pages/dashboard/cbo/search'));

// CBOUser
const CBOUserListPage = lazy(() => import('src/pages/dashboard/cboUser/list'));
const CBOUserAddPage = lazy(() => import('src/pages/dashboard/cboUser/add'));
const CBOUserDetailPage = lazy(() => import('src/pages/dashboard/cboUser/detail'));
const CBOUserEditPage = lazy(() => import('src/pages/dashboard/cboUser/edit'));

// User
const UsersListPage = lazy(() => import('src/pages/dashboard/users/list'));
const UsersAddPage = lazy(() => import('src/pages/dashboard/users/add'));
const UsersDetailPage = lazy(() => import('src/pages/dashboard/users/detail'));
const UsersEditPage = lazy(() => import('src/pages/dashboard/users/edit'));
const UsersSearchPage = lazy(() => import('src/pages/dashboard/users/search'));

// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

// Jobs
const JobBrowsePage = lazy(() => import('src/pages/dashboard/jobs/browse'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/jobs/create'));
const CompanyDetailPage = lazy(() => import('src/pages/dashboard/jobs/companies/detail'));

// Logistics
const LogisticsDashboardPage = lazy(() => import('src/pages/dashboard/logistics/dashboard'));
const LogisticsFleetPage = lazy(() => import('src/pages/dashboard/logistics/fleet'));

// Orders
const OrderListPage = lazy(() => import('src/pages/dashboard/orders/list'));
const OrderDetailPage = lazy(() => import('src/pages/dashboard/orders/detail'));

// Products
const ProductListPage = lazy(() => import('src/pages/dashboard/products/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/products/create'));

// Social
const SocialFeedPage = lazy(() => import('src/pages/dashboard/social/feed'));
const SocialProfilePage = lazy(() => import('src/pages/dashboard/social/profile'));

// Other
const AccountPage = lazy(() => import('src/pages/dashboard/account'));
const AnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const CryptoPage = lazy(() => import('src/pages/dashboard/crypto'));
const EcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));


export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'academy',
        children: [
          {
            index: true,
            element: <AcademyDashboardPage />
          },
          {
            path: 'courses',
            children: [
              {
                path: ':courseId',
                element: <AcademyCoursePage />
              }
            ]
          }
        ]
      },
      {
        path: 'blog',
        children: [
          {
            index: true,
            element: <BlogPostListPage />
          },
          {
            path: 'create',
            element: <BlogPostCreatePage />
          },
          {
            path: ':postId',
            element: <BlogPostDetailPage />
          }
        ]
      },
      {
        path: 'members',
        children: [
          {
            index: true,
            element: <MemberListPage />
          },
          {
            path: ':memberId',
            element: <MemberDetailPage />
          },
          {
            path: ':memberId/edit',
            element: <MemberEditPage />
          },
          {
            path: 'add',
            element: <MemberAddPage />
          },
          {
            path: 'search',
            element: <MemberSearchPage />
          },
          {
            path: ':memberId/summary',
            element: <MemberSummaryPage />
          },
          {
            path: ':memberId/history',
            element: <MemberHistoryPage />
          },
          {
            path: ':memberId/add-referral',
            element: <MemberReferralPage />
          },
          {
            path: ':memberId/update-referral',
            element: <MemberReferralUpdatePage />
          },
          {
            path: ':memberId/update-flexfund',
            element: <MemberFlexFundUpdatePage />
          },
          {
            path: ':memberId/flexfund-fields-update',
          element: <MemberFlexFundFieldsUpdatePage />
          },
          {
            path: ':memberId/add-services',
            element: <MemberServicesPage />
          },
          {
            path: ':memberId/update-services',
            element: <MemberServiceUpdatePage />
          },
          {
            path: ':memberId/flex-funds',
            element: <MemberFlexFundPage />
          },
          {
            path: ':memberId/history/:historyId',
            element: <MemberHistoryDetailPage />
          },
          {
            path: ':memberId/consent-status',
            element: <MemberConsentPage />
          },
          {
            path: ':memberId/check-in-status',
            element: <MemberCheckInPage />
          },
          {
            path: ':memberId/enrollment-status',
            element: <MemberEnrollmentPage />
          },
          {
            path: ':memberId/sdoh-status',
            element: <MemberSdohPage />
          },
        ]
      },
      {
        path: 'statusSearch',
        children: [
          {
            index: true,
            element: <StatusSearchListPage />
          },
          // {
          //   // index: true,
          //   path: 'add',
          //   element: <StatusSearchAddPage />
          // },
          // {
          //   // index: true,
          //   path: ':statusSearchId',
          //   element: <StatusSearchDetailPage />
          // },
          // {
          //   // index: true,
          //   path: ':statusSearchId/edit',
          //   element: <StatusSearchEditPage />
          // },
          {
            // index: true,
            path: 'search',
            element: <StatusSearchSearchPage />
          },
        ]
      },
      {
        path: 'cbo',
        children: [
          {
            index: true,
            element: <CBOListPage />
          },
          {
            // index: true,
            path: 'add',
            element: <CBOAddPage />
          },
          {
            // index: true,
            path: ':cboId',
            element: <CBODetailPage />
          },
          {
            // index: true,
            path: ':cboId/edit',
            element: <CBOEditPage />
          },
          {
            // index: true,
            path: 'search',
            element: <CBOSearchPage />
          },
        ]
      },
      {
        path: 'cboUser',
        children: [
          {
          index: true,
          element: <CBOUserListPage />
          },
          {
          // index: true,
          path: 'add',
          element: <CBOUserAddPage />
          },
          {
          // index: true,
          path: ':cboUserId',
          element: <CBOUserDetailPage />
          },
          {
          // index: true,
          path: ':cboUserId/edit',
          element: <CBOUserEditPage />
          },      
        ]
      },
      {
        path: 'users',
        children: [
          {
          index: true,
          element: <UsersListPage />
          },
          {
          // index: true,
          path: 'add',
          element: <UsersAddPage />
          },
          {
          // index: true,
          path: ':users',
          element: <UsersDetailPage />
          },
          {
          // index: true,
          path: ':users/edit',
          element: <UsersEditPage />
          }, 
          {
            // index: true,
            path: 'search',
            element: <UsersSearchPage />
          },          
        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <JobBrowsePage />
          },
          {
            path: 'create',
            element: <JobCreatePage />
          },
          {
            path: 'companies',
            children: [
              {
                path: ':companyId',
                element: <CompanyDetailPage />
              }
            ]
          }
        ]
      },
      {
        path: 'logistics',
        children: [
          {
            index: true,
            element: <LogisticsDashboardPage />
          },
          {
            path: 'fleet',
            element: <LogisticsFleetPage />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            index: true,
            element: <OrderListPage />
          },
          {
            path: ':orderId',
            element: <OrderDetailPage />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            index: true,
            element: <ProductListPage />
          },
          {
            path: 'create',
            element: <ProductCreatePage />
          }
        ]
      },
      {
        path: 'social',
        children: [
          {
            path: 'feed',
            element: <SocialFeedPage />
          },
          {
            path: 'profile',
            element: <SocialProfilePage />
          }
        ]
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'analytics',
        element: <AnalyticsPage />
      },
      {
        path: 'blank',
        element: <BlankPage />
      },
      {
        path: 'calendar',
        element: <CalendarPage />
      },
      {
        path: 'chat',
        element: <ChatPage />
      },
      {
        path: 'crypto',
        element: <CryptoPage />
      },
      {
        path: 'ecommerce',
        element: <EcommercePage />
      },
      {
        path: 'file-manager',
        element: <FileManagerPage />
      },
      {
        path: 'kanban',
        element: <KanbanPage />
      },
      {
        path: 'mail',
        element: <MailPage />
      }
    ]
  }
];
