import { useTheme } from '@mui/material/styles';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
    <img style={{ width:"100%", height:"100%", borderRadius:"4px" }} src="/lesar/images/Logo.webp" alt="Lesar Development Consultants" />
    // <>
    // </>
    );
};
