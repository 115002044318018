import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import jwt_decode from 'jwt-decode';

export const useSections = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token);
  const is_superuser = decodedToken.is_superuser;

  // const is_superuser = true
  // console.log("is_superuser",is_superuser)
  const { t } = useTranslation();

  return useMemo(() => {
    // {console.log("Is Superuser?",is_superuser)};
    return [
      {
        // subheader: t(tokens.nav.concepts),
        items: [
          // is_superuser=="false"? null :
           {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.customers.index
              },
              {
                title: t(tokens.nav.add),
                path: paths.dashboard.customers.add
              },
            ]
          },
          {
            title: t(tokens.nav.statusSearch),
            path: paths.dashboard.statusSearch.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.statusSearch.index
              },
           ]
          },
          is_superuser==false? '' : {
          // {
            title: t(tokens.nav.cbo),
            path: paths.dashboard.cbo.index,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.cbo.index
              },
              {
                title: t(tokens.nav.add),
                path: paths.dashboard.cbo.add
              },
            ].filter((items)=>items!=null)
            // ]
          },
          // is_superuser=="false"? '' : {
          // // {
          //   title: t(tokens.nav.cboUser),
          //   path: paths.dashboard.cboUser.index,
          //   icon: (
          //   <SvgIcon fontSize="small">
          //     <Users03Icon />
          //   </SvgIcon>
          //   ),
          //   items: [
          //   {
          //   title: t(tokens.nav.list),
          //   path: paths.dashboard.cboUser.index
          //   },
          //   ].filter((items)=>items!=null)
          //   },
            is_superuser==false? '' : {
            // {
              title: t(tokens.nav.users),
              path: paths.dashboard.users.index,
              icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
              ),
              items: [
              {
              title: t(tokens.nav.list),
              path: paths.dashboard.users.index
              },
              {
                title: t(tokens.nav.add),
                path: paths.dashboard.users.add
              },
              ].filter((items)=>items!=null)
              },
            
        // ].filter((items)=>items!=null)
        ]
      },
      {
        subheader: t(tokens.nav.pages),
      },
    ];
  }, [t]);
};
