import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './app';

console.log("ENV:", process.env.NODE_ENV)
// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.warn = () => {}
//   console.debug = () => {}
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
    {/* <HashRouter> */}
      <Suspense>
        <App />
      </Suspense>
      {/* </HashRouter> */}
    </BrowserRouter>
  </HelmetProvider>
);
