import { handleUnauthorized } from "src/contexts/auth/jwt-context";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const fetchServiceType = async () => {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
  
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    return fetch(`${API_ENDPOINT}/api/management/service_type/`, requestOptions)
      .then(response => {
        if (response.status === 401) {
          handleUnauthorized();
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('An error occurred:', error);
      });
  }

  export const updateMemberService = async (id, data) => {
    try {
      const token = localStorage.getItem('token');
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `jwt ${token}`);
      myHeaders.append('Content-Type', 'application/json');
  
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(data)
      };
  
      const response = await fetch(`${API_ENDPOINT}/api/management/member-service/${id}/`, requestOptions);
      if (response.status === 401) {
        handleUnauthorized();
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      console.log(e);
    }
  }
  
export const getMemberServiceById = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member-service/?mem_id=${id}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const createMemberService = async (data) => {
  try {
    const token = localStorage.getItem('token')
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/member-service/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const getMemberServiceList = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/memberservice/list/?member_id=${id}`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}

export const fetchMemberServiceHistory = async (id, service_type) => {
  const token = localStorage.getItem('token');
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `jwt ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${API_ENDPOINT}/api/management/statushistory/member/${id}/${service_type}/`, requestOptions)
    .then(response => {
      if (response.status === 401) {
        handleUnauthorized();
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('An error occurred:', error);
    });
}

export const getTotalFlexFund = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `jwt ${token}`);
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    const response = await fetch(`${API_ENDPOINT}/api/management/total_flex_fund_paid/${id}/`, requestOptions);
    if (response.status === 401) {
      handleUnauthorized();
    }

    const responseData = await response.json();
    return responseData;
  } catch (e) {
    console.log(e);
  }
}