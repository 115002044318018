import {createSlice} from '@reduxjs/toolkit';

// define initial state for this slice
const initialState = {
    loggedInUser : {}
}

// define the slice, with reducers
export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedInUser: (state, action) => {
            state.loggedInUser = action.payload;
        }
    }
});

// export actions
export const {
    setLoggedInUser
} = user.actions;

//selectors for extracting from state
export const selectLoggedInUser = (state) => state.user.loggedInUser;